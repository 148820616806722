import React, { useEffect, useState } from 'react';
import { Edit, Loader2, Plus, Trash2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Job } from "../../types";
import { jobsApi } from "../../api";


const JobsList: React.FC = () => {
	const [ jobs, setJobs ] = useState<Job[]>( [] );
	const [ isLoading, setIsLoading ] = useState( true );
	const [ error, setError ] = useState<string>( '' );
	
	useEffect( () => {
		loadJobs();
	}, [] );
	
	const loadJobs = async () => {
		try {
			setIsLoading( true );
			const response = await jobsApi.getAll();
			setJobs( response.data );
			setError( '' );
		} catch ( error ) {
			setError( 'Failed to load jobs' );
			console.error( 'Error loading jobs:', error );
		} finally {
			setIsLoading( false );
		}
	};
	
	const handleDeleteJob = async ( id: string ) => {
		if ( !window.confirm( 'Are you sure you want to delete this job?' ) ) {
			return;
		}
		
		try {
			await jobsApi.delete( id );
			setJobs( jobs.filter( job => job.id !== id ) );
		} catch ( error ) {
			console.error( 'Error deleting job:', error );
			alert( 'Failed to delete job' );
		}
	};
	
	if ( isLoading ) {
		return (
			<div className="flex justify-center items-center h-64">
				<Loader2 className="h-8 w-8 animate-spin text-blue-500"/>
			</div>
		);
	}
	const isErrorList =
			<div className="text-center text-red-600 p-4">
				<p>{ error }</p>
				<button
					onClick={ loadJobs }
					className="mt-2 text-blue-500 hover:text-blue-700"
				>
					Try again later
				</button>
			</div>
	return (
		<div className="space-y-6">
			<div className="flex justify-between items-center">
				<h2 className="text-2xl font-bold">Vacancy management</h2>
				<Link
					to="create"
					className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200 text-center"
				>
					<Plus className="mr-2 h-5 w-5"/>
					Add a vacancy
				</Link>
			</div>
			{ error ?
				isErrorList :
				<div className="space-y-4">
				{ jobs.length === 0 ? (
					<p className="text-center text-gray-500 py-8">
						No vacancies available
					</p>
				) : (
					<div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
						{ jobs.map( ( job ) => (
						<div key={ job.id } className="bg-white p-6 rounded-lg shadow hover:shadow-md transition duration-200">
							<div className="flex justify-between items-start">
								<div className="flex-1">
									<h3 className="text-xl font-bold mb-4">{ job.title }</h3>
								</div>
								<div className="flex space-x-2 ml-4">
									<Link
										to={ `edit/${ job.id }` }
										className="p-2 text-blue-600 hover:text-blue-800 transition duration-200"
									>
										<Edit className="h-5 w-5"/>
									</Link>
									<button
										onClick={ () => job.id && handleDeleteJob( job.id ) }
										className="p-2 text-red-600 hover:text-red-800 transition duration-200"
									>
										<Trash2 className="h-5 w-5"/>
									</button>
								</div>
							</div>
						</div>
						) ) }
					
					</div>
				) }
			</div>
			}
		</div>
	);
};

export default JobsList;
