import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { News } from "../../types";
import { newsApi } from "../../api";

const MAX_CONTENT_LENGTH = 400;

const NewsForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isEdit = Boolean(id);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string>("");
  const [news, setNews] = useState<News>({
    title: "",
    description: "",
    image: null,
    date: new Date().toISOString().split("T")[0],
  });

  useEffect(() => {
    if (isEdit && id) {
      loadNews(id);
    }
  }, [isEdit, id]);

  const loadNews = async (newsId: string) => {
    try {
      setIsLoading(true);
      const response = await newsApi.getById(newsId);
      const formattedDate = response.data.date
        ? new Date(response.data.date).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0];
      setNews({
        ...response.data,
        date: formattedDate,
      });
    } catch (error) {
      setError("Failed to load news");
      console.error("Error loading news:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("File too large. Maximum size: 5MB");
        return;
      }

      try {
        const urlResponse = await newsApi.getUploadUrl(file.type);
        const { uploadUrl, key } = urlResponse.data;

        await newsApi.uploadToS3(uploadUrl, file);

        setNews((prev) => ({
          ...prev,
          image: `/${key}`,
        }));

        const reader = new FileReader();
        reader.onloadend = () => {
          const previewElement = document.getElementById(
            "imagePreview",
          ) as HTMLImageElement;
          if (previewElement) {
            previewElement.src = reader.result as string;
          }
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading image:", error);
        setError("Failed to upload image");
      }
    }
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;
    if (description.length <= MAX_CONTENT_LENGTH) {
      setNews((prev) => ({ ...prev, description }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!news.image) {
      setError("Please add image");
      return;
    }

    setIsSaving(true);
    try {
      if (isEdit && id) {
        await newsApi.update({ ...news, id });
      } else {
        await newsApi.create(news);
      }
      navigate("/admin/news");
    } catch (error) {
      console.error("Error saving news:", error);
      setError("Failed to save news");
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">
        {isEdit ? "Edit news" : "Add news"}
      </h2>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-md">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-white p-6 rounded-lg shadow space-y-6">
          <div>
            <label className="block text-sm font-medium mb-2">Title</label>
            <input
              type="text"
              value={news.title}
              onChange={(e) => setNews({ ...news, title: e.target.value })}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              maxLength={100}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">Date</label>
            <input
              type="date"
              value={news.date}
              onChange={(e) => setNews({ ...news, date: e.target.value })}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">Image</label>
            <div className="space-y-2">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full"
                required={!news.image}
              />
              {news.image && (
                <div className="mt-2">
                  <img
                    src={news.image}
                    alt="Preview"
                    className="max-h-48 rounded object-cover"
                  />
                </div>
              )}
              <p className="text-sm text-gray-500">Maximum size: 5MB</p>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              Content
              <span className="text-sm text-gray-500 ml-2">
                ({news.description.length}/{MAX_CONTENT_LENGTH})
              </span>
            </label>
            <textarea
              value={news.description}
              onChange={handleContentChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={4}
              required
              maxLength={MAX_CONTENT_LENGTH}
              placeholder="Write your text..."
            />
          </div>

          <div className="flex justify-end space-x-4 pt-4">
            <Link
              to="/admin/news"
              className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-50 transition duration-200"
            >
              Cancel
            </Link>
            <button
              type="submit"
              disabled={isSaving}
              className={`px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200
                ${isSaving ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {isSaving ? "Saving..." : isEdit ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewsForm;
