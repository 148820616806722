import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import { authApi } from "../api";

interface LoginCredentials {
	username: string;
	password: string;
}

const Login: React.FC = () => {
	const navigate = useNavigate();
	const [credentials, setCredentials] = useState<LoginCredentials>({
		username: '',
		password: ''
	});
	const [error, setError] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	
	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		setError('');
		
		try {
			await authApi.login(credentials);
			navigate('/admin/jobs');
		} catch (error) {
			setError('Invalid email or password');
		} finally {
			setIsLoading(false);
		}
	};
	
	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-100">
			<div className="p-8 bg-white rounded-lg shadow-md w-96">
				<h2 className="text-2xl font-bold mb-6 text-center">Admin panel</h2>
				
				{error && (
					<div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-md flex items-center">
						<AlertCircle className="h-4 w-4 mr-2" />
						<p>{error}</p>
					</div>
				)}
				
				<form onSubmit={handleLogin}>
					<div className="space-y-4">
						<div>
							<label className="block text-sm font-medium mb-1">Email</label>
							<input
								type="text"
								value={credentials.username}
								onChange={(e) => setCredentials({
									...credentials,
									username: e.target.value
								})}
								className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
								disabled={isLoading}
							/>
						</div>
						
						<div>
							<label className="block text-sm font-medium mb-1">Password</label>
							<input
								type="password"
								value={credentials.password}
								onChange={(e) => setCredentials({
									...credentials,
									password: e.target.value
								})}
								className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
								disabled={isLoading}
							/>
						</div>
						
						<button
							type="submit"
							className={`w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition duration-200
                ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
							disabled={isLoading}
						>
							Login
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
