import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { Job } from "../../types";
import { jobsApi } from "../../api";
import RichTextEditor from "../editor/RichTextEditor";

const JobForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isEdit = Boolean(id);

  const [job, setJob] = useState<Job>({
    title: "",
    description: "",
    shortDescr: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (isEdit && id) {
      loadJob(id);
    }
  }, [isEdit, id]);

  const loadJob = async (jobId: string) => {
    try {
      setIsLoading(true);
      const response = await jobsApi.getById(jobId);
      setJob(response.data);
      setError("");
    } catch (error) {
      setError("Failed to load job");
      console.error("Error loading job:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      if (isEdit && id) {
        await jobsApi.update(job);
      } else {
        await jobsApi.create(job);
      }
      navigate("/admin/jobs");
    } catch (error) {
      console.error("Error saving job:", error);
      setError("Failed to save job");
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">
        {isEdit ? "Editing a job vacancy" : "Creating a new vacancy"}
      </h2>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-md">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-white p-6 rounded-lg shadow space-y-6">
          <div>
            <label className="block text-sm font-medium mb-2">Job title</label>
            <input
              type="text"
              value={job.title}
              onChange={(e) => setJob({ ...job, title: e.target.value })}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">
              Short job description
            </label>
            <input
              type="text"
              value={job.shortDescr}
              onChange={(e) => setJob({ ...job, shortDescr: e.target.value })}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">
              Full job description
            </label>
            <RichTextEditor
              value={job.description}
              onChange={(content) => {
                setJob({ ...job, description: content });
              }}
              placeholder="Enter a full job description..."
            />
          </div>

          <div className="flex justify-end space-x-4">
            <Link
              to="/admin/jobs"
              className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-50 transition duration-200"
            >
              Cancel
            </Link>
            <button
              type="submit"
              disabled={isSaving}
              className={`px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200
                ${isSaving ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {isSaving ? "Saving..." : isEdit ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JobForm;
