import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Edit, Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { News } from "../../types";
import { newsApi } from "../../api";

const NewsList: React.FC = () => {
	const [ news, setNews ] = useState<News[]>( [] );
	const [ isLoading, setIsLoading ] = useState( true );
	const [ error, setError ] = useState<string>( '' );
	
	useEffect( () => {
		loadNews();
	}, [] );
	
	const loadNews = async () => {
		try {
			setIsLoading(true);
			const response = await newsApi.getAll();
			const sortedNews = response.data.sort((a, b) => {
				return new Date(a.date as string).getTime() - new Date(b.date as string).getTime();
			});
			setNews(sortedNews);
		} catch (error) {
			setError('Failed to load news');
			console.error('Error loading news:', error);
		} finally {
			setIsLoading(false);
		}
	};
	
	const handleDeleteNews = async ( id: string ) => {
		if ( !window.confirm( 'Are you sure you want to delete this news item?' ) ) {
			return;
		}
		
		try {
			await newsApi.delete( id );
			setNews( news.filter( item => item.id !== id ) );
		} catch ( error ) {
			console.error( 'Error deleting news:', error );
			alert( 'Failed to delete news' );
		}
	};
	
	const formatDate = ( dateString: string ) => {
		const date = new Date( dateString );
		return date.toLocaleDateString( 'en-US', {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		} );
	};
	
	if ( isLoading ) {
		return (
			<div className="flex justify-center items-center h-64">
				<Loader2 className="h-8 w-8 animate-spin text-blue-500"/>
			</div>
		);
	}
	
	const isErrorList =
		<div className="text-center text-red-600 p-4">
			<p>{ error }</p>
			<button
				onClick={ loadNews }
				className="mt-2 text-blue-500 hover:text-blue-700"
			>
				Try again later
			</button>
		</div>;
	
	return (
		<div>
			<div className="flex justify-between items-center mb-8">
				<h1 className="text-2xl">News management</h1>
				<Link
					to="create"
					className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200 text-center"
				>
					<Plus className="mr-2 h-5 w-5"/>
					Create news
				</Link>
			</div>
			{ error ?
				isErrorList :
				<div className="space-y-4">
					{ news.length === 0 ? (
						<p className="text-center text-gray-500 py-8">
							No news
						</p>
					) : (
						<div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
							{ news.map( ( item ) => (
								<div key={ item.id }
										 className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
									{ item.image && (
										<div className="w-full">
											<img
												src={ item.image }
												alt={ item.title }
												className="w-full h-64 object-cover"
											/>
										</div>
									) }
									
									<div className="p-4">
										<p className="text-gray-500 text-sm mb-2">
											{ formatDate( item.date as string ) }
										</p>
										
										<h3 className="text-xl font-bold mb-2">
											{ item.title }
										</h3>
										
										<p className="text-gray-600 mb-4 line-clamp-2">
											{ item.description }
										</p>
										
										<div className="flex justify-end items-center pt-2">
											<Link
												to={ `edit/${ item.id }` }
												className="p-2 text-blue-600 hover:text-blue-800 transition duration-200"
											>
												<Edit className="h-5 w-5"/>
											</Link>
											<button
												onClick={ () => item.id && handleDeleteNews( item.id ) }
												className="p-2 hover:text-red-500 transition-colors"
											>
												<Trash2 className="h-5 w-5"/>
											</button>
										</div>
									</div>
								</div>
							) ) }
						</div>
					) }
				</div>
			}</div>
	);
};


export default NewsList;
