import React from 'react';
import { Building2, LogOut, NewspaperIcon } from 'lucide-react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

const Layout: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	
	const handleLogout = () => {
		localStorage.removeItem('authToken');
		navigate( '/admin/login' );
	};
	
	return (
		<div className="min-h-screen bg-gray-100">
			<nav className="bg-white shadow-sm">
				<div className="max-w-7xl mx-auto">
					<div className="flex border-b">
						<Link
							to="jobs"
							className={ `flex items-center px-6 py-4 text-sm hover:bg-gray-50 ${
								location.pathname.includes( '/jobs' )
									? 'border-b-2 border-blue-500 text-blue-500'
									: 'text-gray-600'
							}` }
						>
							<Building2 className="h-5 w-5 mr-2"/>
							Careers
						</Link>
						<Link
							to="news"
							className={ `flex items-center px-6 py-4 text-sm hover:bg-gray-50 ${
								location.pathname.includes( '/news' )
									? 'border-b-2 border-blue-500 text-blue-500'
									: 'text-gray-600'
							}` }
						>
							<NewspaperIcon className="h-5 w-5 mr-2"/>
							News
						</Link>
						<button
							onClick={ handleLogout }
							className="flex items-center px-6 py-4 text-sm text-gray-600 hover:bg-gray-50 ml-auto"
						>
							<LogOut className="h-5 w-5 mr-2"/>
							<span className="hidden sm:inline">Logout</span>
						</button>
					</div>
				</div>
			</nav>
			
			<main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
				<Outlet/>
			</main>
		</div>
	);
};

export default Layout;
