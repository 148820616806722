import React, { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import Sources from "quill";
import { EditorProps, QuillEditorRef } from "../../types";

const Editor = forwardRef<QuillEditorRef, EditorProps>(
  (
    {
      readOnly,
      placeholder,
      defaultValue,
      onTextChange,
      onSelectionChange,
      value,
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      if (ref && "current" in ref) {
        (ref.current as any)?.enable(!readOnly);
      }
    }, [ref, readOnly]);

    useEffect(() => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement("div"),
      );
      editorContainer.style.fontSize = "16px";
      editorContainer.style.height = "350px";

      const colors = [
        "#E36A32",
        "#2681DE",
        "#000000",
        "#434343",
        "#666666",
        "#999999",
        "#b7b7b7",
        "#cccccc",
        "#d9d9d9",
        "#efefef",
        "#f3f3f3",
        "#ffffff",
        "#980000",
        "#ff0000",
        "#ff9900",
        "#ffff00",
        "#00ff00",
        "#00ffff",
        "#4a86e8",
        "#0000ff",
        "#9900ff",
        "#ff00ff",
        "#e6b8af",
        "#f4cccc",
        "#fce5cd",
        "#fff2cc",
        "#d9ead3",
        "#d0e0e3",
        "#c9daf8",
        "#cfe2f3",
        "#d9d2e9",
        "#ead1dc",
        "#dd7e6b",
        "#ea9999",
        "#f9cb9c",
        "#ffe599",
        "#b6d7a8",
        "#a2c4c9",
        "#a4c2f4",
        "#9fc5e8",
        "#b4a7d6",
        "#d5a6bd",
        "#cc4125",
        "#e06666",
        "#f6b26b",
        "#ffd966",
        "#93c47d",
        "#76a5af",
        "#6d9eeb",
        "#6fa8dc",
        "#8e7cc3",
        "#c27ba0",
        "#a61c00",
        "#cc0000",
        "#e69138",
        "#f1c232",
        "#6aa84f",
        "#45818e",
        "#3c78d8",
        "#3d85c6",
        "#674ea7",
        "#a64d79",
        "#85200c",
        "#990000",
        "#b45f06",
        "#bf9000",
        "#38761d",
        "#134f5c",
        "#1155cc",
        "#0b5394",
        "#351c75",
        "#741b47",
        "#5b0f00",
        "#660000",
        "#783f04",
        "#7f6000",
        "#274e13",
        "#0c343d",
        "#1c4587",
        "#073763",
        "#20124d",
        "#4c1130",
      ];

      const quill = new Quill(editorContainer, {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, false] }],
              ["bold", "italic", "underline"],
              ["link", "blockquote"],
              [{ color: colors }, { background: colors }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
            ],
          },
        },
        placeholder: placeholder || "Enter your description...",
      });

      if (ref && "current" in ref) {
        ref.current = quill as any;
      }
      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      if (value) {
        quill.clipboard.dangerouslyPasteHTML(0, value);
      } else if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on("text-change", (delta: any, oldDelta: any, source: any) => {
        onTextChangeRef.current?.(delta, oldDelta, source);
      });

      quill.on(
        "selection-change",
        (range: any, oldRange: any, source: Sources) => {
          onSelectionChangeRef.current?.(range, oldRange, source);
        },
      );

      return () => {
        if (ref && "current" in ref) {
          ref.current = null;
        }
        container.innerHTML = "";
      };
    }, [ref]);

    return <div ref={containerRef} className="bg-white"></div>;
  },
);

Editor.displayName = "Editor";

export default Editor;
