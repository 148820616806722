import axios from "axios";
import { Job, News, LoginCredentials } from "../types";

interface BaseEntity {
  id?: string;
}

const encodeBase64 = (username: string, password: string): string => {
  return btoa(`${username}:${password}`);
};

const api = axios.create({
  baseURL: "/Prod/db",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    config.headers["X-API-KEY"] = authToken;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      localStorage.removeItem("authToken");

      window.location.href = "/admin/login";
    }
    return Promise.reject(error);
  },
);

function createApiService<T extends BaseEntity>(type: string) {
  return {
    getAll: () =>
      api.post<T[]>("", {
        action: "read",
        type,
      }),

    getById: (id: string) =>
      api.post<T>("", {
        action: "readOne",
        type,
        data: { id },
      }),

    create: (data: Omit<T, "id">) =>
      api.post<T>("", {
        action: "create",
        type,
        data,
      }),

    update: (data: T) =>
      api.post<T>("", {
        action: "update",
        type,
        data,
      }),

    delete: (id: string) =>
      api.post<T>("", {
        action: "delete",
        type,
        data: { id },
      }),
  };
}

export const jobsApi = {
  ...createApiService<Job>("careers"),
};

export const newsApi = {
  ...createApiService<News>("news"),
  getUploadUrl: (contentType: string) =>
    api.post("", {
      action: "getUploadUrl",
      type: "fileUpload",
      data: { contentType },
    }),
  uploadToS3: async (url: string, file: File) => {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
        "X-API-KEY": localStorage.getItem("authToken") as string,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to upload file");
    }

    return response;
  },
};

export const authApi = {
  login: async (credentials: LoginCredentials) => {
    const token = encodeBase64(credentials.username, credentials.password);

    try {
      await api.post(
        "",
        {
          action: "read",
          type: "careers",
        },
        {
          headers: {
            "X-API-KEY": token,
          },
        },
      );
      localStorage.setItem("authToken", token);
      return {
        isLoggedIn: true,
        token,
      };
    } catch (error) {
      throw new Error("Invalid credentials");
    }
  },

  logout: () => {
    localStorage.removeItem("authToken");
    window.location.href = "/admin/login";
  },
};
