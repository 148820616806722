import React, { useRef, useState } from "react";
import Editor from "./Editor";
import { EditorComponentProps, QuillEditorRef } from "../../types";

const RichTextEditor: React.FC<EditorComponentProps> = ({
  onChange,
  value,
  placeholder,
}) => {
  const [readOnly, setReadOnly] = useState(false);
  const quillRef = useRef<QuillEditorRef>(null);

  const handleTextChange = (delta: any, oldContents: any, source: string) => {
    if (source === "user" && onChange) {
      const text = quillRef.current?.getSemanticHTML() || "";
      onChange(text);
    }
  };

  return (
    <div className="border rounded-lg">
      <Editor
        ref={quillRef}
        value={value}
        readOnly={readOnly}
        placeholder={placeholder}
        onTextChange={handleTextChange}
      />
    </div>
  );
};

export default RichTextEditor;
