import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Login from './pages/Login';
import Layout from './components/Layout';
import JobsList from './components/jobs/JobsList';
import JobForm from './components/jobs/JobForm';
import NewsList from './components/news/NewsList';
import NewsForm from './components/news/NewsForm';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
	const authState = localStorage.getItem('authToken');
	const isAuthenticated = !!authState;
	
	if (!isAuthenticated) {
		return <Navigate to="/admin/login" replace />;
	}
	
	return <>{children}</>;
};

const router = createBrowserRouter([
	{
		path: '/admin',
		children: [
			{
				path: 'login',
				element: <Login />
			},
			{
				path: '',
				element: (
					<ProtectedRoute>
						<Layout />
					</ProtectedRoute>
				),
				children: [
					{
						path: 'jobs',
						children: [
							{ index: true, element: <JobsList /> },
							{ path: 'create', element: <JobForm /> },
							{ path: 'edit/:id', element: <JobForm /> }
						]
					},
					{
						path: 'news',
						children: [
							{ index: true, element: <NewsList /> },
							{ path: 'create', element: <NewsForm /> },
							{ path: 'edit/:id', element: <NewsForm /> }
						]
					},
					{
						index: true,
						element: <Navigate to="/admin/jobs" replace />
					}
				]
			}
		]
	},
	{
		path: '/',
		element: <Navigate to="/admin" replace />
	}
]);

const Router = () => {
	return <RouterProvider router={router} />;
};

export default Router;
